<!--
 * @Description: 首页组件
 * @Author: hai-27
 * @Date: 2020-02-07 16:23:00
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:12
 -->
<template>
	<div class="home" id="home" name="home">
		<!-- 轮播图 -->
		<div class="carousel">
			<div class="carousel-left">
				<ul>
					<li class="carousel-hover1 flexspb" v-for="(item, index) in goodsClassList" :key="index">
						<a href="JavaScript:;" class="flexal">{{ item.Title }} <!-- &nbsp; | &nbsp; -->
							<!-- <div style="width: 80px;height: 16px;margin-left: 4px;" class="text-1"> -->
							<!-- 	<span	style="font-size: 14px;margin-right: 0;float: left;" class="">{{ item.Intro}}</span> -->
							<!-- </div> -->
						</a><span>></span>
						<div class="carousel1-hover">
							<ul>
								<li v-for="(good2, gindex2) in item.ChildrenList" :key="gindex2"
									style="margin-bottom: 16px;">
									<p class="goodstitle"
										style="font-size: 14px;color: #09c867;line-height: 36px;display: inline-block;"
										@click="categoryClick(good2,good2)">{{good2.Title}}</p>
									<div style="display: flex;flex-wrap: wrap;">
										<div class="goodsinfo" v-for="(good, gindex) in good2.ChildrenList"
											:key="gindex">
											<a href="javascript:[0]" @click="categoryClick(good,item)">
												<el-image class="image" style="width: 100px; height: 100px"
													:src="good.ImgUrl"></el-image>		
												<p class="name">{{good.Title}}</p>
											</a>
										</div>
										<!-- <span class="fenge">|</span> -->
									</div>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
			<div class="swiper mySwiper" style="width: 674px; margin:0 16px">
				<el-carousel height="380px">
					<el-carousel-item v-for="(item, index) in carousel" :key="index">
						<img style="height:380px;width: 674px;" :src="getUrl(item.ImgUrl)" :alt="item.Title" />
					</el-carousel-item>
				</el-carousel>
				<div class="user-banner boxsiz">
					<ul class="title clear">
						<li class="fl name">客户名称</li>
						<li class="fl type">相关型号</li>
						<li class="fl msg">动态信息</li>
					</ul>
					<div class="user-msgs-wrapper">
						<ul class="user-msgs">
							<li class="user-msg clear">
								<div class="fl name ellipsis">186****8494</div>
								<div class="fl type ellipsis">MAF1005GAD262AT000</div>
								<div class="fl msg ellipsis">下单5309.90元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">155****6523</div>
								<div class="fl type ellipsis">SPCM-AQRH-13-FC</div>
								<div class="fl msg ellipsis">将44个元器件型号加入购物车</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">155****4504</div>
								<div class="fl type ellipsis">H21A2</div>
								<div class="fl msg ellipsis">下单13859.87元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">132****3148</div>
								<div class="fl type ellipsis">SV-LED-214E</div>
								<div class="fl msg ellipsis">连续搜索了5个电子元器件型号</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">135****4878</div>
								<div class="fl type ellipsis">NTCG0805-KIT</div>
								<div class="fl msg ellipsis">下单34282.12元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">138****2388</div>
								<div class="fl type ellipsis">JF08R0R051020UA</div>
								<div class="fl msg ellipsis">连续搜索了2个电子元器件型号</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">187****7550</div>
								<div class="fl type ellipsis">760390012</div>
								<div class="fl msg ellipsis">下单8073.54元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">135****4417</div>
								<div class="fl type ellipsis">T101MH9AVBE</div>
								<div class="fl msg ellipsis">将30个元器件型号加入购物车</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">146****6106</div>
								<div class="fl type ellipsis">BAV99-TP</div>
								<div class="fl msg ellipsis">连续搜索了4个电子元器件型号</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">147****5804</div>
								<div class="fl type ellipsis">LPA-C011301S-20</div>
								<div class="fl msg ellipsis">将41个元器件型号加入购物车</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">181****9008</div>
								<div class="fl type ellipsis">1N4148W-TP</div>
								<div class="fl msg ellipsis">下单674.00元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">132****7680</div>
								<div class="fl type ellipsis">RPI-243</div>
								<div class="fl msg ellipsis">将18个元器件型号加入购物车</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">136****9094</div>
								<div class="fl type ellipsis">RPI-121</div>
								<div class="fl msg ellipsis">下单2263.71元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">130****9191</div>
								<div class="fl type ellipsis">316040004</div>
								<div class="fl msg ellipsis">连续搜索了6个电子元器件型号</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">132****9802</div>
								<div class="fl type ellipsis">0407006.WR</div>
								<div class="fl msg ellipsis">将28个元器件型号加入购物车</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">138****3665</div>
								<div class="fl type ellipsis">HFBR-RNS005Z</div>
								<div class="fl msg ellipsis">下单1004.22元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">187****4528</div>
								<div class="fl type ellipsis">MTP6H-E6-C</div>
								<div class="fl msg ellipsis">下单13960.00元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">176****8199</div>
								<div class="fl type ellipsis">CCSMX1-FBM-RG178-24</div>
								<div class="fl msg ellipsis">连续搜索了1个电子元器件型号</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">123****8912</div>
								<div class="fl type ellipsis">EKMB1309111K</div>
								<div class="fl msg ellipsis">下单73.45元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">139****6590</div>
								<div class="fl type ellipsis">BUK963R1-40E</div>
								<div class="fl msg ellipsis">下单2077.00元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">134****7329</div>
								<div class="fl type ellipsis">MAF1005GAD262AT000</div>
								<div class="fl msg ellipsis">将36个元器件型号加入购物车</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">186****6473</div>
								<div class="fl type ellipsis">SPCM-AQRH-13-FC</div>
								<div class="fl msg ellipsis">下单49527.54元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">134****1947</div>
								<div class="fl type ellipsis">H21A2</div>
								<div class="fl msg ellipsis">下单36452.52元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">181****1976</div>
								<div class="fl type ellipsis">SV-LED-214E</div>
								<div class="fl msg ellipsis">下单4356.00元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">182****1508</div>
								<div class="fl type ellipsis">NTCG0805-KIT</div>
								<div class="fl msg ellipsis">下单4.90元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">137****2017</div>
								<div class="fl type ellipsis">JF08R0R051020UA</div>
								<div class="fl msg ellipsis">下单1474.61元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">xin****i.cn</div>
								<div class="fl type ellipsis">760390012</div>
								<div class="fl msg ellipsis">下单1808.00元</div>
							</li>
							<li class="user-msg clear">
								<div class="fl name ellipsis">139****1111</div>
								<div class="fl type ellipsis">T101MH9AVBE</div>
								<div class="fl msg ellipsis">下单3000.00元</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="user">
					<div style="width: 248px;height: 220px;padding-top: 30px;box-sizing: border-box;">
						<div class="avat flexcen" style=" margin: 0 auto 10px;">
							<!-- box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 20%); -->
							<div style="box-sizing: border-box;border-radius: 50%;width: 68px;height: 68px;"
								class="flexcen">
								<img src="/pc_static/img/logo.png" alt=""
									v-if="(($store.getters.getUser.length == 0) || $store.getters.getUser.Avatar == '')" />
								<img :src="$store.getters.getUser.Avatar" alt="" v-else>
							</div>

						</div>
						<div class="userName">
							{{ this.$store.getters.getUser.UserName?this.$store.getters.getUser.UserName:'HI~,您好' }}
						</div>
						<div class="two flexspb " v-if="($store.getters.getUser.length != 0)">
							<router-link to="/PersonalCenter/Personal">
								<div class="bots flexcen">会员中心</div>
							</router-link>

							<div class="bots flexcen bgc bots2" v-on:click="logout">退出登录</div>
						</div>
						<div class="two flexcen flexspb" v-if="($store.getters.getUser.length == 0)">
							<a href="javascript:[0]" @click="login">
								<div class="bots flexcen">登录</div>
							</a>
							<a href="javascript:[0]" @click="registergo">
								<div class="bots flexcen bgc bots2">注册 </div>
							</a>
						</div>
					</div>
					<!-- <div class="news-banner">
						<div class="title clear">
							<span class="title-l fl">资讯</span>
							<div class="fr title-r">
								<router-link to="/news">更多</router-link>
								<span class="iconfont iconarrow_page_right_default" style="margin-left: 4px;">></span>
							</div>
						</div>
						<div class="news-items-div">
							<ul class="news-items">
								<li class="new-item" v-for="(item,index) in Newlist" :key="index">
									
									<a href="javascript:[0]" class="clear"  @click="newClick(item)">
										<span
											class="mark fl line-block z-mark">资讯</span>
										<p class="fl ellipsis">{{item.Title}}</p>
									</a></li>
								
							</ul>
						</div>
					</div> -->
				</div>
			</div>
			<!-- 轮播图END -->
		</div>
		<!-- 	<section class="section-1 width1225">
			<div class="title lb-box">
				<h3 class="title1 line-block boxsiz" :class="activeNum == 1 ? 'active' : ''" @click="nums(1)">合作供应商</h3>
				<h3 class=" title2 line-block boxsiz" :class="activeNum == 2 ? 'active' : ''" @click="nums(2)">合作品牌商
				</h3>
			</div>
			<div class="content">
				<div class="clear article-div">
					<article class="fl width1225 article" v-if="activeNum == 1">
						<ul class="clear items">
							<li class="fl item supplier-item" v-for="(item) in supp" :key="item.Id">
								<router-link :to="{ path: '/MerchantShop' , query: { SupplierId:item.Id } }">
									<img :src="item.Logo" :alt="item.Intro">
								</router-link>
							</li>

						</ul>
					</article>
					<article class="fl width-1204 article" v-if="activeNum == 2">
						<ul class="clear items">
							<li class="fl item brand-item" v-for="(item) in supp2" :key="item.Id">
								<router-link :to="{ path: '/MerchantShop' , query: { BrandId:item.Id } }">
									<img :src="item.Logo" :alt="item.Intro">
								</router-link>
							</li>

						</ul>
					</article>
				</div>
			</div>
		</section> -->
		<section class="section-2 width1225">
			<h3 class="title">热卖元件</h3>
			<article class="article clear">
				<div class="fl adv">
					<a href=""><img src="https://oss.icdeal.com/www/202101041152545352f1881ffcf6ee687e77d8e9bdbd66.png"
							target="_blank"></a>
				</div>
				<div class="fr goods-div boxsiz">
					<div class="good-items clear yqj-items">
						<ul class="good-items-ul fl clear" v-for="(item, index) in like" :key="index">
							<li class="good-item fl boxsiz" v-for="(items, indexs) in like[index]" :key="indexs">
								<div class="good-item-div boxsiz">
									<router-link :to="{ path: '/goods/details' , query: { id: items.Id } }">
										<img :src="getUrl(items.ImgUrl)" alt="LT1013DDR_运算放大器/缓冲放大器IC交易"><strong
											class="name">{{ items.Name }}</strong>
										<p class="classify ellipsis">{{ items.Name }}</p>
										<p class="price ellipsis">￥{{ items.Price }}</p>
									</router-link>
								</div>
							</li>
						</ul>
					</div>
					<div class="common-banner-tips  clear">
						<ul class="fl Indexes clear"></ul>
						<div class="fr more">
							<router-link :to="{ path: '/goods' }">
								更多
							</router-link>
						</div>
					</div>
				</div>
			</article>
		</section>
		<section class="section-3 width1225">
			<h3 class="title">分类推荐</h3>
			<article class="article">
				<div class="good-wrapper">
					<div class="good-items clear classify-items">
						<ul class="good-items-ul fl clear">
							<li class="good-item fl boxsiz" v-for="(item,index) in newGoods" :key="index">
								<div class="good-item-div boxsiz" data-lever="2">
									<a href="javascript:[0]" @click="categoryClick2(item)">
										<img :src="getUrl(item.ImgUrl)">
										<p class="classify ellipsis">{{ item.Title }}</p>
										<p class="classify-s ellipsis">
											{{ item.Intro }}
										</p>
									</a>
								</div>
							</li>



						</ul>
					</div>
				</div>
				<div class="common-banner-tips  clear">
					<ul class="fl Indexes clear"></ul>
					<div class="fr more">
						<router-link :to="{ path: '/goods' }">
							更多
						</router-link>
					</div>
				</div>
			</article>
		</section>
		<section class="section-5 width1225 boxsiz">
			<div class="friend-items lb-box">
				<div class="item line-block">
					<img src="https://oss.icdeal.com/test/202011060647210a962eb666fd7433c487b02332084d29.png"
						alt="全球现货 一站配齐">
					<div class="item-r">
						<p class="t ellipsis">全球现货 一站配齐</p>
					</div>
				</div>
				<div class="item line-block">
					<img src="https://oss.icdeal.com/test/202011060647524a8e6dc139ee6ed297b1a76582d5bf2f.png"
						alt="价格透明 省钱省心">
					<div class="item-r">
						<p class="t ellipsis">价格透明 省钱省心</p>
					</div>
				</div>
				<div class="item line-block">
					<img src="https://oss.icdeal.com/test/202011060647455c93fcd84b95b8e86bdc803c32859477.png"
						alt="原厂代理 分销授权">
					<div class="item-r">
						<p class="t ellipsis">原厂代理 分销授权</p>
					</div>
				</div>
				<div class="item line-block">
					<img src="https://oss.icdeal.com/test/20201106064728ef2521258bbce201815a813dab71a982.png"
						alt="闪电发货 极速配货">
					<div class="item-r">
						<p class="t ellipsis">闪电发货 极速配货</p>
					</div>
				</div>
			</div>
		</section>
	</div>


</template>

<script>
	import {
		mapActions
	} from "vuex";
	export default {
		data() {
			return {
				carousel: "", // 轮播图数据
				phoneList: "", // 手机商品列表
				Newlist: [],
				goodsClassList: [], //商品类型列表
				RedGoodsClassList: [], //推荐商品分类列表
				goodsLength: 0,
				newGood: [],
				GuessIndex: 0,
				newGoods: [],
				like: [],
				likeLeng: 0,
				activeNum: 1,
				timer2: null,
				timer4: null,
				timer5: null,
				index4: 0,
				index5: 0,
				move4: false,
				move5: false,
				supp: [],
				supp2: []
			};
		},
		created() {
			// 获取轮播图数据
			this.loadBannerList();
			this.loadGoodClassList();
			this.loadGoodClassListToRed();
			this.NewsList()
		},
		mounted() {
			this.textBanner(".user-msgs", ".user-msg", 4, "timer2", "-32px");

			// this.goodsBanner(
			// 	".section-3",
			// 	".classify-items",
			// 	".good-items-ul",
			// 	".good-item",
			// 	"timer5",
			// 	"-1225",
			// 	"index5",
			// 	"move5"
			// );
		},
		methods: {
			...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
			login() {
				// 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
				this.setShowLogin(true);
			},
			registergo() {
				sessionStorage.setItem("registerBool", true);
				this.setShowLogin(true);
			},
			newClick(item) {
				this.$router.push("/newDetail?Id=" + item.Id)
			},
			async NewsList() {
				var res = await this.postdata("/api/News/NewsList", {
					"page": 1,
					"limit": 8,
				});
				if (res.code == 200) {
					this.Newlist = res.data.items
				}
			},
			categoryClick(item, parent) {
				this.categoryID = item.Id
				if (item.Id > 0) {
					var thisitem = JSON.stringify(item)
					localStorage.removeItem('clickGoods')
					localStorage.setItem("clickGoods", thisitem);
				}
				this.$router.push("/goods/connector?Name=" + parent.Title + '&&categoryID=' + item.Id + '&&parentId=' +
					parent.Id)
			},
			categoryClick2(item) {
				this.categoryID = item.Id
				if (item.Id > 0) {
					var thisitem = JSON.stringify(item)
					localStorage.removeItem('clickGoods')
					localStorage.setItem("clickGoods", thisitem);
				}
				this.$router.push("/goods/connector?Name=" + item.Title + '&&categoryID=' + item.Id + '&&parentId=' + item
					.Id)
			},
			async loadBannerList() {
				var res = await this.postdata("/api/News/BannerList", {
					page: 1,
					limit: 10,
					channelCode: "index",
				});
				if (res.code == 200) {
					this.carousel = res.data.items;
				}
				res = await this.postdata('/api/News/BrandList', {
					"page": 1,
					"limit": 99
				})
				this.supp2 = res.data.items
				res = await this.postdata('/api/News/SupplierList', {
					"page": 1,
					"limit": 99
				})
				this.supp = res.data.items
			},

			sliceArray(array, size) {
				var result = [];
				for (var x = 0; x < Math.ceil(array.length / size); x++) {
					var start = x * size;
					var end = start + size;
					result.push(array.slice(start, end));
				}
				return result;
			},
			logout() {
				this.visible = false;
				// 清空本地登录信息
				localStorage.setItem("userToken", "");
				// 清空vuex登录信息
				this.setUser("");
				this.notifySucceed("成功退出登录");
				this.$router.push({
					path: "/",
				});
			},
			async loadGoodClassList() {
				var res = await this.postdata("/api/News/ClassList	", {
					page: 1,
					limit: 14,
					queryType: 1,
				});
				if (res.code == 200) {
					this.goodsClassList = res.data.items;
				}
			},
			async loadGoodClassListToRed() {
				var res = await this.postdata("/api/News/ClassList", {
					page: 1,
					limit: 6,
					isIndex: 1,
				});
				if (res.code == 200) {
					this.newGoods = res.data.items;

				}

				res = await this.postdata("/api/Goods/GoodsList", {
					page: 1,
					limit: 20,
					"IsHot": 1
				});
				if (res.code == 200) {
					let list = res.data.items;
					if (list.length > 4) {
						this.likeLeng = parseInt(list.length / 4);
						if (list.length % 4 == 0)
							this.likeLeng = list.length / 4;
						if (list.length % 4 != 0)
							this.likeLeng =
							parseInt(list.length / 4) + 1;
						this.like = this.sliceArray(list, 4);
					} else {
						this.likeLeng = 1
						this.like = this.sliceArray(list, 4);
					}
					if (this.like.length > 0) {
						this.$nextTick(i => {
							this.goodsBanner(
								".section-2",
								".yqj-items",
								".good-items-ul",
								".good-item",
								"timer4",
								"-1004",
								"index4",
								"move4"
							);
						})

					}

				}
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			textBanner: function(parent, children, maxLength, timer, height) {
				//客户购买信息轮播
				var length = $(parent).find(children).length;
				var me = this;
				if (length > maxLength) {
					me[timer] = setInterval(function() {
						me.textBannerFunction(parent, children, height);
					}, 2000);
				}
				$(parent).on("mouseover", children, function() {
					clearInterval(me[timer]);
				});

				$(parent).on("mouseout", children, function() {
					me[timer] = setInterval(function() {
						me.textBannerFunction(parent, children, height);
					}, 2000);
				});
			},
			textBannerFunction: function(parent, children, height) {
				$(parent).animate({
					top: height
				}, 500, function() {
					var firstChild = $(parent).find(children).eq(0);
					firstChild.remove().appendTo($(parent));
					$(parent).css({
						top: "0",
					});
				});
			},
			goodsBanner: function(
				firstP,
				parent,
				parent1,
				children,
				timer,
				width,
				index,
				move) {
				var remainder = $(firstP).find(parent1).length - 1;
				console.log($(firstP).find(parent1).length, $(firstP), $(firstP).find(parent1), 580)
				var me = this;
				if (remainder > 1) {
					$(firstP).find(".common-banner-tips .Indexes").show();
					var bannerNavStr = "";
					for (var i = 0; i < remainder; i++) {
						bannerNavStr +=
							'<li class="fl ' + (i == 0 ? "active" : "") + '"></li>';
					}
					$(firstP).find(".common-banner-tips .Indexes").html(bannerNavStr);
					me[timer] = setInterval(function() {
						me.goodsBannerFunction(firstP, parent, width, index, remainder);
					}, 5000);
				}

				$(parent).on("mouseover", children, function() {
					clearInterval(me[timer]);
				});

				$(parent).on("mouseout", children, function() {
					clearInterval(me[timer]);
					me[timer] = setInterval(function() {
						me.goodsBannerFunction(firstP, parent, width, index, remainder);
					}, 5000);
				});
				$(firstP)
					.find(".common-banner-tips li")
					.on("click", function() {
						if (me[move]) {
							return;
						}
						clearInterval(me[timer]);
						me[move] = true;
						var index = $(this).index();
						me[index] = index;
						$(this).addClass("active").siblings().removeClass("active");
						$(parent).animate({
								left: width * index + "px"
							},
							1000,
							function() {
								me[move] = false;
								clearInterval(me[timer]);
								me[timer] = setInterval(function() {
									me.goodsBannerFunction(
										firstP,
										parent,
										width,
										index,
										remainder
									);
								}, 5000);
							}
						);
					});
			},
			goodsBannerFunction: function(
				firstP,
				parent,
				width,
				index,
				remainder
			) {
				//热卖元件和分类推荐轮播
				console.log(firstP,
					parent,
					width,
					index,
					remainder, 975)
				var me = this;
				me[index]++;
				if (me[index] == remainder) {
					me[index] = 0;
				}
				$(firstP)
					.find(".common-banner-tips li")
					.eq(me[index])
					.addClass("active")
					.siblings()
					.removeClass("active");
				$(parent).animate({

					left: width * me[index] + "px"
				}, 1000, function() {});
			},
			nums(index) {
				this.activeNum = index
			}
		},
	};
</script>

<style lang="less" scoped>
	@import "../assets/css/index.css";

	.carousel {
		width: 1225px;
		margin: auto;
		position: relative;
		height: 570px;
		overflow: hidden;
		// padding-left: 25px;
		box-sizing: border-box;
		padding-right: 8px;
		display: flex;
	}

	.carousel>.carousel-left {
		width: 230px;
		background-color: #fff;
		// position: absolute;
		// left: 0;
		// top: 0;
		z-index: 10;
		height: 100%;
		padding-right: 16px;
	}

	.carousel>.carousel-left .carousel-hover1 {
		list-style: none;
		width: 100%;
		padding: 10.5px 0px;
		background-color: #fff;
		padding-left: 16px;
	}

	.carousel>.carousel-left .carousel-hover1:hover {
		background-color: #09c867;
		cursor: pointer;
	}

	.carousel>.carousel-left .carousel-hover1:hover span {
		color: #fff;
	}

	.carousel>.carousel-left .carousel-hover1:hover>a {
		color: #fff;
	}

	.carousel>.carousel-left .carousel-hover1:hover>.carousel1-hover {
		display: block;
		padding: 12px 32px 0 32px;
	}

	.carousel>.carousel-left .carousel-hover1:hover>.carousel1-hover .goodsinfo {
		font-size: 12px;
	}

	.carousel>.carousel-left .carousel-hover1:hover>.carousel1-hover .goodsinfo .name:hover {
		color: #09c867;
	}

	.carousel>.carousel-left .carousel-hover1:hover>.carousel1-hover .fenge {
		font-size: 16px;
		color: #000;
		margin: 0 3px;
	}

	.carousel>.carousel-left .carousel-hover1,
	.carousel>.carousel-left .carousel-hover1 a {
		text-decoration: none;
		font-size: 14px;
		color: #000;
	}

	.carousel>.carousel-left .carousel-hover1 .goodsinfo {
		width: 20%;
		margin-bottom: 8px;
	}

	.carousel>.carousel-left .carousel-hover1 .goodsinfo a {
		font-size: 12px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.carousel>.carousel-left .carousel-hover1 .goodsinfo a:hover {
		color: #09c867;
	}

	.carousel>.carousel-left .carousel-hover1 .goodsinfo a .name {
		width: 100%;
		text-align: center;
		margin-top: 6px;
	}

	.carousel>.carousel-left .carousel-hover1 span {
		display: block;
		float: right;
		// color: aliceblue;
		margin-right: 20px;
	}

	.carousel>.carousel-left .carousel1-hover {
		background-color: #f5f5f5;
		width: 678px;
		height: 700px;
		position: absolute;
		left: 246px;
		top: 0px;
		display: none;
	}

	.carousel>.carousel-left .carousel1-hover li {
		list-style: none;
		// float: left;
		// display: flex;
	}

	.carousel>.carousel-left .carousel1-hover li>a {
		color: #616161;
		text-decoration: none;
		float: left;
		word-break: break-all;
	}

	.carousel>.carousel-left .carousel1-hover li>a:hover {
		color: #09c867;
	}

	.mySwiper .user-banner {
		margin-top: 10px;
		background: #fff;
		width: 100%;
		padding: 0 12px;
		height: 178px;
		box-sizing: border-box;
	}

	.mySwiper .user-banner .title {
		height: 41px;
		color: #666666;
		border-bottom: 1px solid #ebeef5;
		line-height: 41px;
	}

	.mySwiper .user-banner .title li.name {
		margin: 0;
	}

	.mySwiper .user-banner .title li {
		margin-left: 20px;
	}

	.user-banner .user-msgs-wrapper .user-msg div.name {
		margin-left: 0;
	}

	.user-banner .user-msgs-wrapper .user-msg div {
		margin-left: 20px;
	}

	.mySwiper .user-banner .user-msgs-wrapper {
		height: 136px;
		overflow: hidden;
	}

	.mySwiper .user-banner .user-msgs-wrapper .user-msgs {
		position: relative;
		z-index: 1;
	}

	.mySwiper .user-banner .user-msgs-wrapper .user-msg {
		font-size: 12px;
		color: #333333;
		height: 32px;
		line-height: 32px;
		margin-top: 2px;
	}

	.mySwiper .user-banner .name {
		width: 153px;
	}

	.mySwiper .user-banner .type {
		width: 213px;
	}

	.mySwiper .user-banner .msg {
		width: 223px;
	}

	.ellipsis {
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		-icab-text-overflow: ellipsis;
		-khtml-text-overflow: ellipsis;
		-moz-text-overflow: ellipsis;
		-webkit-text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.el-carousel__arrow--left {
		left: 246px !important;
	}

	.box-hd {
		height: 58px;
		margin: 20px 0 0 0;
	}

	.box-hd .more {
		float: right;
	}

	.user {
		width: 248px;
		height: 570px;
		// background: #a88e77;
		position: absolute;
		right: 0;
		top: 0;
		padding: 0 12px;

		background-color: #fff;
	}

	.user .avat {
		// border: 4px solid #fff;
		width: 68px;
		height: 68px;
		// background: #fff;
		// border-radius: 50%;

	}


	.user .avat img {
		width: 60px;
		height: 60px;
		border-radius: 50%;
	}

	.user .userName {
		font-size: 14px;
		text-align: center;
	}

	.user .two {
		width: 85%;
		padding: 0 16px;
		margin: 15px auto 0 auto;
		box-sizing: border-box;
	}

	.user .two .bots {
		width: 72px;
		height: 32px;
		font-size: 14px;
		line-height: 32px;
		background: #09c867;
		border-radius: 3px;
		color: #fff;
		cursor: pointer;
		border: 1px solid #09c867;
	}

	.user .two .bots:hover {
		background-color: #00af56;
		color: #fff;
		border-color: #00af56;
	}

	.user .two .bgc {
		color: #fff;
		background: #09c867;
	}

	.user .two .bots2 {
		width: 72px;
		height: 32px;
		font-size: 14px;
		line-height: 32px;
		background: #fff;
		border-radius: 3px;
		color: #000;
		cursor: pointer;
		border: 1px solid #000;
	}

	.user .two .bots2:hover {
		background-color: #09c867;
		color: #fff;
		border-color: #09c867;
	}


	.user .twos {
		width: 100%;
		padding: 0 16px;
		box-sizing: border-box;
		// position: absolute;
		// bottom: 32px;
		// left: 0;
		margin-top: 15px;
	}

	.user .twos .boxs {
		width: 55px;
		height: 55px;
		background-color: #f5f5f5;
		border-radius: 8px;
		color: #fa5d59;
		font-size: 12px;
		cursor: pointer;
	}

	.user .twos .boxs img {
		margin-bottom: 6px;
		width: 25px;
		height: 25px;
	}

	//咨询
	.user .news-banner {
		height: 325px;
	}

	.user .news-banner .title {
		padding-bottom: 7px;
		border-bottom: 1px solid #ebeef5;
	}

	.user .news-banner .title .title-l,
	.user .news-banner .title .title-r {
		font-size: 14px;
		color: #333333;
	}

	.user .news-banner .title .title-l.title-r a,
	.user .news-banner .title .title-r.title-r a {
		font-size: 14px;
		color: #333333;
		cursor: pointer;
	}

	.user .news-banner .title .title-l.title-r i,
	.user .news-banner .title .title-r.title-r i {
		font-size: 14px;
		font-weight: 600;
	}

	.user .news-banner .news-items-div {
		height: 298px;
		overflow: hidden;
	}

	.user .news-banner .news-items-div .news-items {
		position: relative;
	}

	.user .news-banner .news-items-div .news-items .new-item {
		padding: 10px 0 5px;
	}

	.user .news-banner .news-items-div .news-items .new-item a {
		display: block;
	}

	.user .news-banner .news-items-div .news-items .new-item .mark.z-mark {
		background: #ebf3ff;
		color: #3d7fff;
	}

	.user .news-banner .news-items-div .news-items .new-item .mark {
		width: 42px;
		height: 22px;
		border-radius: 3px;
		text-align: center;
		line-height: 22px;
	}

	.user .news-banner .news-items-div .news-items .new-item p {
		padding: 0 5px;
		width: 190px;
		color: #333;
		line-height: 22px;
	}


	/* .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
} */

	.el-carousel__item:nth-child(2n) {
		background-color: #fff;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #fff;
	}

	.blocks {
		width: 608px;
		/* height: 782px; */
		background: #ffffff;
		padding: 0 24px 24px;
		box-sizing: border-box;
	}

	.blocks .titls {
		width: 100%;
		height: 88px;
	}

	.blocks .titls .les {
		font-size: 28px;
	}

	.blocks .titls .rigs {
		font-size: 18px;
		color: #967d67;
	}

	.home .section-1 {
		margin-top: 20px;
	}

	.home .section-1 .title {
		display: flex;
		justify-content: center;
		text-align: center;
		height: 40px;
	}

	.home .section-1 .title h3.active {
		font-size: 20px;
		border-bottom: 4px solid #09c867;
		font-weight: 600;
	}

	.home .section-1 .title h3 {
		font-size: 18px;
		color: #333;
		width: 104px;
		cursor: pointer;
		height: 35px;
		transition: all linear 0.2s;
		margin: 0 12px;
	}

	.home .section-1 .content .article {
		margin-left: -13px;
	}

	.home .section-1 .content .items {
		margin-top: 8px;
		padding-bottom: 12px;
	}

	.home .section-1 .content .items .item {
		margin: 12px 0 0 12px;
	}

	.home .section-1 .content .items .item a {
		display: block;
		width: 160px;
		height: 80px;
		background-color: #fff;
		text-align: center;
		transition: all linear 0.25s;
	}

	.home .section-1 .content .items .item a img {
		width: 100px;
		height: 50px;
		margin-top: 15px;
	}

	.home .section-1 .content .items .more {
		margin: 12px 0 0 12px;
		width: 160px;
		height: 80px;
		background-color: #fff;
		text-align: center;
		line-height: 80px;
	}

	.home .section-1 .content .items .more a {
		font-size: 18px;
		color: #333;
		cursor: pointer;
	}

	.home .section-2 .title {
		font-size: 20px;
		color: #333;
		text-align: center;
		padding: 18px 0 30px;
		font-weight: 600;
	}

	.home .section-2 .article .adv {
		width: 220px;
		height: 320px;
		position: relative;
		z-index: 2;
	}

	.home .section-2 .article .adv a img {
		height: 320px;
		width: 220px;
	}

	.home .section-2 .article .goods-div {
		width: 1004px;
		background-color: #fff;
		height: 320px;
		padding: 25px 0 0;
		position: relative;
		z-index: 1;
		left: -1px;
		margin-left: -1px;
		overflow: hidden;
	}

	.home .section-2 .article .goods-div .good-items {
		width: 10000px;
		position: relative;
		left: 0;
	}

	.home .section-2 .article .goods-div .good-items .good-item {
		width: 201px;
		padding: 0 7px;
		border-left: 1px solid #ebeef5;
		height: 250px;
	}

	.home .section-2 .article .goods-div .good-items .good-item .good-item-div {
		height: 270px;
		position: relative;
		top: -15px;
		padding: 20px 10px 0;
	}

	.home .section-2 .article .goods-div .good-items .good-item .good-item-div a {
		display: block;
		text-align: center;
	}

	.home .section-2 .article .goods-div .good-items .good-item .good-item-div a img {
		width: 120px;
		height: 120px;
	}

	.home .section-2 .article .goods-div .good-items .good-item .good-item-div a .name {
		font-size: 16px;
		color: #333;
		display: block;
		text-align: center;
		word-wrap: break-word;
		word-break: normal;
		margin: 10px 0 8px;
		height: 41px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.home .section-2 .article .goods-div .good-items .good-item .good-item-div a .classify {
		font-size: 14px;
		color: #999;
	}

	.home .section-2 .article .goods-div .good-items .good-item .good-item-div a .price {
		font-size: 14px;
		color: #ff3b30;
		padding-top: 5px;
	}

	.common-banner-tips .Indexes {
		padding: 20px 0 0 45px;
		display: none;
	}

	.common-banner-tips .more {
		padding: 10px 20px 0 0;
	}

	.home .section-3 {
		overflow: hidden;
	}

	.home .section-3 .title {
		font-size: 20px;
		color: #333;
		padding: 30px 0 30px;
		text-align: center;
		font-weight: 600;
	}

	.home .section-3 .article {
		width: 1225px;
		height: 300px;
		background-color: #fff;
	}

	.home .section-3 .article .good-wrapper {
		padding: 30px 0 0;
		width: 1225px;
		margin-left: -1px;
	}

	.home .section-3 .article .good-wrapper .good-items {
		position: relative;
		width: 10000px;
	}

	.home .section-3 .article .good-wrapper .good-items .good-item {
		width: 198.5px;
		padding: 0 10px;
		border-left: 1px solid #ebeef5;
		height: 200px;

	}

	.home .section-3 .article .good-wrapper .good-items .good-item .good-item-div {
		height: 230px;
		position: relative;
		top: -15px;
		padding: 20px 10px 0;
	}

	.home .section-3 .article .good-wrapper .good-items .good-item .good-item-div a {
		display: block;
		text-align: center;
	}

	.home .section-3 .article .good-wrapper .good-items .good-item .good-item-div a img {
		height: 120px;
		width: 120px;
	}

	.home .section-3 .article .good-wrapper .good-items .good-item .good-item-div a .classify {
		height: 41px;
		line-height: 41px;
		font-size: 16px;
		color: #333;
	}

	.home .section-3 .article .good-wrapper .good-items .good-item .good-item-div a .classify-s {
		font-size: 14px;
		color: #999;
		padding-top: 8px;
	}

	.home .section-5 {
		height: 160px;
		background-color: #fff;
		margin-top: 40px;
		padding: 20px 33px 0 33px;
	}

	.home .section-5 .friend-items {
		width: 1225px;
		margin-left: -40px;
	}

	.home .section-5 .friend-items .item:first-child {
		margin-left: 80px;
	}

	.home .section-5 .friend-items .item {
		margin-left: 140px;
		text-align: center;
	}

	.home .section-5 .friend-items img {
		height: 80px;
		width: 80px;
	}

	.home .section-5 .friend-items .item-r {
		width: 160px;
	}

	.home .section-5 .friend-items .item-r .t {
		font-size: 16px;
		color: #333;
		height: 28px;
		line-height: 28px;
	}
</style>